/* Homepage.css */
:root {
  --red: rgb(233, 30, 99);
  --purple: rgb(206, 147, 216);
  --orange: rgb(255, 165, 0);
  --green: rgb(102, 187, 106);
}

html {
  overflow: hidden !important;
}

/* font-family: 'Castoro Titling', serif;
font-family: 'Karma', serif;
font-family: 'Libre Caslon Display', serif;
*/

body {
  font-family: "Playfair Display", serif !important;
}

.homepage {
  z-index: 1;
  background-image: url("./img/inpiration/streetlight.png");
  background-size: cover;
  height: 100vh;
}

.nav-bar {
  position: sticky;
  z-index: 999;
  /* display: flex; */
  /* justify-content: space-between; */
  /* align-items: center; */
  background-color: transparent !important;
  box-shadow: none !important;
  padding: 10px;
  background: linear-gradient(to bottom, black 30%, transparent);
}

@media (max-width: 600px) {
  .nav-bar {
    background: black;
  }
}

.hamburger {
  background: none;
  border: none;
  color: white;
  font-size: 28px;
  font-weight: bold;
  padding: 0 20px;
}

.logo {
  /* padding-left: 20px; */
  perspective: 1000px;
}

.logo img {
  display: block;
  width: 50px;
  height: 50px;
  transition: transform 0.6s;
}

.logo:hover img {
  transform: rotateY(180deg);
}

.main-logo {
  z-index: 1 !important;
  position: absolute;
  top: 40%;
  left: 50%;
  transform: translate(-50%, -50%);
  opacity: 0;
  transition: opacity 8000ms;
  width: 400px;
  height: 300px;
  background-image: url("./img/logo/logo-04.png");
  background-size: contain;
  background-repeat: no-repeat;
}

.fade-in {
  opacity: 1;
}

.arrow-up {
  z-index: 150 !important;
  animation: hoverArrow 2s infinite;

  position: absolute;
  top: -10px;
  left: 50%;
  transform: translateX(-50%);

  width: 40px;
  height: 40px;
  border-radius: 50%;

  display: flex;
  align-items: center;
  justify-content: center;

  background: #222222e2;
  color: var(--red);

  box-shadow: 0 5px 5px rgba(0, 0, 0, 0.1);
  cursor: pointer;
}

.arrow-up:hover {
  color: white;
}

.hover-arrow {
  z-index: 150 !important;
  animation: hoverArrow 2s infinite;

  position: absolute;
  bottom: 50px;
  left: 50%;
  transform: translateX(-50%);

  width: 40px;
  height: 40px;
  border-radius: 50%;

  display: flex;
  align-items: center;
  justify-content: center;

  background: #22222276;
  /* color: rgb(216,27,96); */
  color: var(--red);

  box-shadow: 0 5px 5px rgba(0, 0, 0, 0.1);
  cursor: pointer;
}

.hover-arrow:hover {
  color: white;
}

@keyframes hoverArrow {
  0% {
    transform: translate(-50%, 20px);
  }
  50% {
    transform: translate(-50%, 30px);
  }
  100% {
    transform: translate(-50%, 20px);
  }
}

/* Panel styles */
.panel1 {
  position: absolute;
  z-index: 100;
  width: 100%;
  height: 90vh;
  overflow: hidden;
  background-color: black;
}

.panel2 {
  position: absolute;
  z-index: 100;
  width: 100%;
  height: 90vh;
  overflow: hidden;
  background-color: rgb(66, 77, 81);
}

.mail-icon {
  /* color: rgb(216,27,96); */
  color: var(--red);
  padding-inline: 20px;
  font-size: large;
}

.mail-icon:hover {
  /* color:white; */
  transform: rotate(15deg);
}

.menu-icon {
  padding-inline: 10px;
}

.menu-icon:hover {
  /* color: rgb(216,27,96); */
  /* transform: rotate(-15deg) */
}

/* .menu-backdrop {
  background-color: rgba(157, 157, 157, 0.297) !important;
  transition: background-color 2s linear
} */

.menu {
  /* background-color: rgb(21, 29, 31); */
  background-color: rgb(39, 50, 53);
}

.drawer-top {
  margin: auto;
  margin-top: 10px;
  display: flex;
  width: 80%;
  height: 50px;
  justify-content: center;
  padding: 10px;
}

.drawer-top img {
  width: 50px;
}

.drawer-bottom {
  margin-bottom: 30px;
  display: flex;
  justify-content: center;
  align-items: flex-end;
  padding: 10px;
  height: 100%;
  gap: 10px;
}

/* Card Styles */

.card-container {
  padding: 20px;
}

.card-grid {
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(300px, 1fr));
  /* grid-template-columns: repeat(4, 1fr); */
  margin: auto;
  width: 90%;
  height: 70%;
  gap: 7.5px;
  padding-bottom: 10px;
}

.card {
  background-size: cover;
  background-position: center;
  height: 200px;
  position: relative;
  overflow: hidden;
}

.card-hover-info {
  position: absolute;
  bottom: 0;
  background-color: rgba(0, 0, 0, 0.6);
  color: white;
  width: 100%;
  padding: 10px;
  transform: translateY(100%);
  transition: transform 0.3s ease-in-out;
}

.card:hover {
  box-shadow: 0px 3px 3px 3px rgba(0, 0, 0, 0.751);
  cursor: pointer;
}

.card:hover .card-hover-info {
  transform: translateY(0);
}

.card h3 {
  margin: 0;
}

.card p {
  margin: 0;
  font-size: 0.9em;
}

/* Info Panel */
.info-panel-container {
  padding: 20px;
}

.two-column-container {
  display: flex;
  align-items: stretch; /* To ensure columns have equal height */
  height: 70% !important;
  max-height: 70vh !important;
}

.left-column,
.right-column {
  width: 50%;
}

.left-column {
  position: relative;
  padding: 20px;
  height: 90%;
  margin-bottom: 30px;
  display: flex;
  align-items: baseline;
  justify-content: center;
}

.main-image {
  margin: auto;
  width: auto;
  height: auto;
  max-height: 60%;
  max-width: 60%;
  display: block;
  filter: grayscale();
}

.small-image {
  margin: auto;
  margin-left: 30%;
  width: 40%;
  position: absolute;
  bottom: 10px;
  transform: rotate(-10deg);
}

.small-image.first {
  left: 0%; /* Adjust for positioning */
  box-shadow: 1px 3px 3px 4px black;
}

.small-image.second {
  left: 30%; /* Adjust for overlap and positioning */
  transform: rotate(10deg); /* Adjust angle */
  box-shadow: 1px 3px 3px 4px black;
}

.right-column {
  padding: 40px; /* Adjust as needed */
  color: white;
  margin-inline: 40px;
  font-size: 1.5rem;
  font-family: "Karma", serif;
  /* font-family: "Libre Caslon Display", serif; */
}

.right-column p {
  width: 70%;
  margin: auto;
  margin-top: 30px;
}

.line-break {
  height: 0px;
  width: 80%;
  border: 1px solid var(--red);
  margin: auto;
  margin-top: 30px;
  margin-bottom: 10px;
}

/* Video Panel */
.video-panel-container {
}

/* Mobile view */
@media screen and (max-width: 600px) {
  .panel2 {
    overflow-y: scroll;
  }
  .card-grid {
    grid-template-columns: repeat(auto-fill, minmax(150px, 1fr));
    height: auto;
  }

  .card {
    height: 150px;
  }
}
